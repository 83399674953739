import React, { ReactElement } from 'react';
import { Box } from '@mui/material';
import {
  CardContainer,
  CardIconDataContainer,
  IconContainer,
  SkeletonLines,
  StyledAppNameText,
  StyledSectionNameText,
  SkeletonContainer,
  SkeletonCardIconDataContainer,
  FavoriteActionButton,
} from './FavoriteCard.style';
import '@diligentcorp/atlas-design-tokens/dist/themes/lens/css/core.css';
import '@diligentcorp/atlas-web-component-icons/lib/drag-vertical/index.js';
import '@diligentcorp/atlas-web-component-icons/lib/star/index.js';
import { AppIcon } from '@diligentcorp/atlas-web-component-global-nav/lib/react';
import { ShowComponentElements, FavoriteCardComponentType, FavoriteCardProps } from '../../../types';
import { useTranslation } from 'react-i18next';
import { stripHtmlTags, replaceKey } from '../../helpers/common';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'dil-drag-vertical-icon': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      'dil-star-icon': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement> & { variant?: string }, HTMLElement>;
    }
  }
}

const generateNonEmptyComponentElements = (componentType: string): ShowComponentElements => {
  //allows to show drag knob and Favorite icon. To be used in component sidebar
  if (componentType == FavoriteCardComponentType.View) {
    return {
      showDragKnob: false,
      showFavoriteButton: true,
    };
  }
  //allows to show drag knob and Favorite icon. To be used in component sidebar
  else if (componentType == FavoriteCardComponentType.Draggable) {
    return {
      showDragKnob: true,
      showFavoriteButton: true,
    };
  }
  // only shows app icon and app name. To be used in carousel component
  else {
    return {
      showDragKnob: false,
      showFavoriteButton: false,
    };
  }
};

const FavoriteCard = ({
  favoriteAppData,
  onFavoriteButtonClick,
  componentType,
  isFavorite,
}: FavoriteCardProps): ReactElement => {
  const componentElements = {
    ...generateNonEmptyComponentElements(componentType),
  };
  const { t } = useTranslation();
  if (componentType !== FavoriteCardComponentType.Skeleton) {
    return (
      <CardContainer
        href={componentType == FavoriteCardComponentType.Default ? favoriteAppData.appLink : null}
        data-testid={`favorite-app-card-${favoriteAppData.appKey}`}
        componentType={componentType}
      >
        <CardIconDataContainer>
          {componentElements.showDragKnob && (
            <dil-drag-vertical-icon data-testid="drag-knob-icon"></dil-drag-vertical-icon>
          )}

          <IconContainer data-testid="favorite-app-icon">
            <AppIcon appKey={favoriteAppData.appKey} size="lg" />
          </IconContainer>
          <Box data-testid="favorite-app-data">
            <StyledAppNameText>{favoriteAppData.appName}</StyledAppNameText>
            <StyledSectionNameText>
              {stripHtmlTags(t(`sections.${replaceKey(favoriteAppData.sectionKey!)}`))}
            </StyledSectionNameText>
          </Box>
        </CardIconDataContainer>
        {componentElements.showFavoriteButton && (
          <FavoriteActionButton
            onClick={(e) => {
              onFavoriteButtonClick(favoriteAppData);
              e.stopPropagation();
            }}
            data-testid="favorite-action-button"
          >
            <dil-star-icon variant={isFavorite ? 'filled' : 'outlined'}></dil-star-icon>
          </FavoriteActionButton>
        )}
      </CardContainer>
    );
  } else {
    return (
      <CardContainer href={null} componentType={componentType} data-testid={'favorite-empty-card'}>
        <SkeletonCardIconDataContainer>
          <IconContainer componentType={componentType} data-testid="skeleton-filled-icon">
            <dil-star-icon variant="filled"></dil-star-icon>
          </IconContainer>
          <SkeletonContainer data-testid="skeleton-load-lines">
            <SkeletonLines width="100%" />
            <SkeletonLines width="80%" />
          </SkeletonContainer>
        </SkeletonCardIconDataContainer>
      </CardContainer>
    );
  }
};

export default FavoriteCard;
