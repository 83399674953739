import * as React from 'react';
import { Menu, Button, MenuItem } from '@mui/material';
import Settings from '@diligentcorp/atlas-react-icons/dist/esm/lens/Settings.js';
import Edit from '@diligentcorp/atlas-react-icons/dist/esm/lens/Edit.js';
import Visible from '@diligentcorp/atlas-react-icons/dist/esm/lens/Visible.js';
import Hidden from '@diligentcorp/atlas-react-icons/dist/esm/lens/Hidden.js';
import { useTranslation } from 'react-i18next';
import FavoriteAppSettings from '../FavoriteAppSettings';
import { CombinedData } from '../../hooks/queries/useCombinedGlobalNavData';
import useScreenSize from '../../hooks/useScreenSize';
import AppConfig from '../../appConfig';

interface FavoriteAppMenuProps {
  toggleFavoriteSection: (isFavoriteVisible: boolean) => void;
  isFavoriteSectionVisible: boolean;
  data: CombinedData;
  onSaveFavoriteApps: () => void;
  displayDiscardMessage: () => void;
}

const FavoriteAppMenu: React.FC<FavoriteAppMenuProps> = ({
  toggleFavoriteSection,
  data,
  onSaveFavoriteApps,
  isFavoriteSectionVisible,
  displayDiscardMessage,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const screenSize = useScreenSize();
  const [openSideBar, setopenSideBar] = React.useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleSideBarClose = (showDiscardMessage?: boolean) => {
    setopenSideBar(false);
    if (showDiscardMessage) {
      displayDiscardMessage();
    }
  };
  const toggleFavoriteMenuItem = () => {
    toggleFavoriteSection(!isFavoriteSectionVisible);
    handleMenuClose();
  };

  const openFavoriteSettings = () => {
    setopenSideBar(true);
    handleMenuClose();
  };

  return (
    <>
      <div>
        <Button
          id="favorite-app-menu-button"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          startIcon={<Settings />}
        >
          {screenSize >= AppConfig.constants.mobileBreakpoint && t('favoriteApps.menu_button_text')}
        </Button>
        <Menu
          id="favorite-app-menu"
          aria-labelledby="favorite-app-menu-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          MenuListProps={{
            'aria-labelledby': `favorite-app-menu-button`,
          }}
          PaperProps={{ sx: { width: '220px' } }}
        >
          <MenuItem onClick={openFavoriteSettings}>
            <Edit /> {t('favoriteApps.menu_item_edit_favorite')}
          </MenuItem>
          <MenuItem onClick={toggleFavoriteMenuItem}>
            {isFavoriteSectionVisible ? (
              <>
                <Hidden />
                {t('favoriteApps.menu_item_hide_favorite')}
              </>
            ) : (
              <>
                <Visible />
                {t('favoriteApps.menu_item_show_favorite')}
              </>
            )}
          </MenuItem>
        </Menu>
      </div>
      <FavoriteAppSettings
        open={openSideBar}
        title={t('favoriteApps.app_settings_title')}
        onClose={handleSideBarClose}
        data={data}
        onSaveFavoriteApps={onSaveFavoriteApps}
      />
    </>
  );
};
export default FavoriteAppMenu;
