import { boardApps, App, GlobalNavData } from '../types/GlobalNavData';
import AppConfig from '../appConfig';
import { CombinedData } from '../hooks/queries/useCombinedGlobalNavData';
import { FavoriteAppData, SectionData } from '../../types';

export const demoApps: boardApps = {
  demoApps: [
    {
      title: 'Risk Manager',
      key: 'risk-manager',
    },
    {
      title: 'Audit',
      key: 'audit',
    },
    {
      title: 'Entities',
      key: 'entities',
    },
    {
      title: 'Market Intelligence',
      key: 'market-intelligence',
    },
    {
      title: 'ESG',
      key: 'accuvio',
    },
    {
      title: 'Activity Center',
      key: 'activity-center',
    },
    {
      title: 'Projects',
      key: 'projects',
    },
  ],
};
export function generateAppID(appData: App[]): number {
  const maximumAppID = appData.reduce((max: number, obj: any) => (obj.id > max ? obj.id : max), 0);
  return maximumAppID + 1;
}

export function replaceKey(key: string) {
  return key.replace(/[_-]/g, '-');
}

export function getTileType(key: string): string {
  if (key === 'boards-cloud-customer-admin') {
    return 'activeApp';
  } else if (demoApps.demoApps.some((app) => app.key === key)) {
    return 'demoApp';
  } else {
    return 'mutedApp';
  }
}

export function generateBoardsData(appId: number, org_id?: string): App[] | [] {
  if (AppConfig.featureToggles.useBoardSectionTabs && getboardEntitledOrgs().includes(parseInt(org_id || ''))) {
    return [
      {
        id: appId + 2,
        key: 'boards-cloud-csp',
        url: 'https://director.diligentboards.com',
        title: 'Boards',
      },
      {
        id: appId + 3,
        key: 'entities',
        url: '', //url wont be used for demo tiles
        title: 'Entities',
      },
    ];
  } else {
    return [];
  }
}

//adding installers data to navbar apps
export function installersData(appId: number, url: string): App[] {
  return [
    {
      id: appId + 4,
      key: 'installers',
      url,
      title: 'Installers',
    },
  ];
}

export function generateEducationAndCertificationData(appId: number): App[] {
  if (AppConfig.environment.isStaging || AppConfig.environment.isPlayground) {
    return [
      {
        id: appId,
        key: 'diligent-institute',
        url: 'https://www.diligentinstitute.com/',
        title: 'Diligent Institute',
      },
      {
        id: appId + 1,
        key: 'academy',
        url: 'https://connect.test.diligent.com/hbfrontdoor/login?startURL=/hbfrontdoor/lmsloginrelay',
        title: 'Diligent Academy',
      },
    ];
  } else {
    return [
      {
        id: appId,
        key: 'diligent-institute',
        url: 'https://www.diligentinstitute.com/',
        title: 'Diligent Institute',
      },
      {
        id: appId + 1,
        key: 'academy',
        url: 'https://connect.diligent.com/hbfrontdoor/login?startURL=/hbfrontdoor/lmsloginrelay',
        title: 'Diligent Academy',
      },
    ];
  }
}

//temporary function to remove compliance maps from navbar apps if boardEffect app is present
export function removeRestrictedApps(GlobalNavData: any): GlobalNavData {
  let appData: App[] = GlobalNavData.data.appSwitcherProps.apps;
  if (appData.some((app) => app.key === 'boardeffect')) {
    appData = appData.filter((app) => app.key !== 'compliance-maps');
  }
  if (appData.some((app) => app.key === 'reports' && AppConfig.environment.isDiligentOnePlatformDomain)) {
    appData = appData.filter((app) => app.key !== 'reports');
  }
  GlobalNavData.data.appSwitcherProps.apps = appData;
  return GlobalNavData;
}
//remove BoardEffect and Community Apps for Boards Experience
export function removeBoardXPRestrictedApps(GlobalNavData: any, org_id?: string): GlobalNavData {
  if (AppConfig.featureToggles.useBoardSectionTabs && getboardEntitledOrgs().includes(parseInt(org_id || ''))) {
    let filteredAppData: App[] = [];
    let appData: App[] = GlobalNavData.data.appSwitcherProps.apps;
    filteredAppData = appData.filter((app) => app.key !== 'boardeffect' && app.key !== 'community');
    GlobalNavData.data.appSwitcherProps.apps = filteredAppData;
  }
  return GlobalNavData;
}

export function getboardEntitledOrgs() {
  let entitledOrgs: number[];
  if (AppConfig.environment.isStaging) {
    entitledOrgs = [1002244];
  } else if (AppConfig.environment.isPreprod) {
    entitledOrgs = [12087]; // -1 is a placeholder for pre-production
  } else if (AppConfig.environment.isProduction) {
    entitledOrgs = [
      30377, 46052, 46054, 46057, 46060, 46063, 46066, 46067, 46068, 46069, 46070, 46071, 46072, 46087, 45950, 46086,
      46085, 46084, 46083, 46082, 46081, 46080, 46079, 46078, 46077, 46076, 46075, 46074, 46073, 46107, 46108, 46109,
      46110, 46111, 46112, 46113, 46114, 46115, 46116, 46117, 46118, 46120, 46121, 46122, 46123, 46124, 46125, 46126,
      46127, 46128, 46129, 46130, 46131, 46132, 46133, 46134, 46105, 46104, 46103, 46102, 46101, 46100, 46099, 46098,
      46097, 46065, 46064, 46062, 46061, 46059, 46003, 46058, 46056, 46055, 46053, 46051, 46050, 46049, 46048, 46047,
      46046, 46045, 46044, 46043, 46042, 46041, 46040, 46039, 46038, 46037, 46036, 46035, 46034, 46033, 46032, 46031,
      46030, 46029, 46028, 46027, 46026, 46025, 46024, 46022, 46021, 46020, 46019, 45932, 45899, 45934, 45931, 46018,
      45933, 13794, 46017, 46016, 46015, 46350, 46677, 46764, 47017, 47018, 47019, 47020, 47021, 47022, 47023, 47024,
      47025, 47026, 47027, 47028, 47029, 47031, 47032, 47033, 47034, 47035, 47036, 47037, 47038, 47039, 47040, 47043,
      47047, 47048, 47049, 47050, 47051, 47052, 47054, 47055, 47057, 47058, 47059, 47060, 47061, 47062, 47068, 47069,
      47070, 47073, 47075, 47076, 47077, 47078, 47079, 47080, 47081, 47082, 47083, 47084, 47085, 47086, 47087, 47088,
      47089, 47090, 47091, 47092, 47093, 47094, 47095, 47096, 47097, 47098, 47099, 47100, 46764, 47102, 47103, 47104,
      47105, 47106, 47107, 47108, 47219, 47330, 47793, 47766, 47826, 47913, 47054, 47073, 47588, 47584, 47330, 47914,
      47915, 47916, 47917, 47918, 47919, 47920, 47921, 47922, 47965, 47583, 47966, 47967, 47734, 47586, 47560, 47562,
      48062, 48061, 48056, 48057, 48058, 48059, 48060, 48073, 48154, 48155, 48156, 48171, 48172, 48218, 48219, 48220,
      48221, 48222, 48217, 48216, 48215, 48214, 46033, 47565, 48213, 48211, 48286, 48287, 48288, 48262, 48303, 48375,
      48376, 48379, 48486, 48487, 48488, 48490, 48491, 48492, 48493, 48494, 47566, 47585, 47587, 48495, 47589, 48496,
      47629, 48501, 48502, 48505, 48375, 48504, 48503, 48500, 48499, 48498, 48564, 48566, 48567, 48623, 48665, 47097,
      48748, 48498, 47583, 48262,
    ]; // -1 is a placeholder for production
  } else {
    entitledOrgs = [865, 83]; //local and playground
  }
  return entitledOrgs;
}
//this component will be used to over ride the global nav api data
export function getHelpDocUrl() {
  return 'https://help.highbond.com/helpdocs/highbond/en-us/Default.htm#cshid=diligent-one-platform-help';
}

export function getContentUrl(appKey: string | undefined) {
  switch (appKey) {
    case 'risk-manager':
      return 'https://play.vidyard.com/qR1uy7Kz6BWc2RHxz3tQhu?disable_popouts=1&disable_analytics=0&preload=auto&disable_larger_player=true&controller=hubs&action=show&type=inline&v=4.2.21';
    case 'market-intelligence':
      return 'https://play.vidyard.com/LSyPkhm4yNNRpuyyvLCGuA?disable_popouts=1&disable_analytics=0&preload=auto&disable_larger_player=true&controller=hubs&action=show&type=inline&v=4.2.21';
    case 'audit':
      return 'https://play.vidyard.com/6dKDzeB7eHYn8Dg6j739K1?disable_popouts=1&disable_analytics=0&preload=auto&disable_larger_player=true&controller=hubs&action=show&type=inline&v=4.2.21';
    case 'entities':
      return 'https://play.vidyard.com/ybsPSBvYqp1z93gei2z6xP?disable_popouts=1&disable_analytics=0&preload=auto&disable_larger_player=true&controller=hubs&action=show&type=inline&v=4.2.21';
    case 'accuvio':
      return 'https://play.vidyard.com/81d4nYQR8VTNGQ47fDwPMh?disable_popouts=1&disable_analytics=0&preload=auto&disable_larger_player=true&controller=hubs&action=show&type=inline&v=4.2.21';
    case 'activity-center':
      return 'https://play.vidyard.com/BPj47nFWavVwhosjgrKerd?disable_popouts=1&disable_analytics=0&preload=auto&disable_larger_player=true&controller=hubs&action=show&type=inline&v=4.2.21';
    case 'projects':
      return 'https://play.vidyard.com/6dKDzeB7eHYn8Dg6j739K1?disable_popouts=1&disable_analytics=0&preload=auto&disable_larger_player=true&controller=hubs&action=show&type=inline&v=4.2.21';
    default:
      return '';
  }
}

export const stripHtmlTags = (str: string): string => {
  return str.replace(/<\/?[^>]+(>|$)/g, '');
};

export const formatFavoritesData = (favoritesData: any, combinedData: CombinedData): FavoriteAppData[] => {
  const formattedData: FavoriteAppData[] = favoritesData.map((item: any) => {
    const appData = combinedData.globalNavData.appSwitcherProps.apps.find((app) => app.key === item.appKey);
    // favorites should only be there if the user is entitled that app
    try {
      if (appData) {
        const sectionData = combinedData.processedData.find((section) => section.key === item.sectionKey)!;
        return {
          ...item,
          sectionKey: sectionData.key,
          appName: appData.title,
          appLink: appData.url,
        };
      }
    } catch (e) {
      console.error(e);
    }
  });

  return formattedData?.filter((item) => item !== undefined);
};

export const formatSectionData = (sectionsData: any, sectionFromCombinedData: SectionData[]): SectionData[] => {
  const formattedData: SectionData[] = sectionsData.map((item: any) => {
    return {
      ...item,
      sectionKey: item.sectionKey,
    };
  });
  const filteredData = formattedData.filter((item) => {
    return sectionFromCombinedData.some((section) => section.sectionKey === item.sectionKey);
  });
  return filteredData;
};

export const sidebarSectionDataOnInitialRender = (sectionFavourites: any, combinedData: CombinedData) => {
  const sectionFromCombinedData = combinedData?.processedData?.map((item) => ({ sectionKey: item.key }));
  return sectionFavourites?.length
    ? formatSectionData(sectionFavourites, sectionFromCombinedData)
    : sectionFromCombinedData;
};

export const sortProcessedData = (sectionData: SectionData[], combinedData: CombinedData): CombinedData => {
  if (!sectionData || sectionData.length === 0) {
    return combinedData;
  }
  // Create a new sorted array based on sectionData order
  const sortedProcessedData = sectionData
    .map((section) => {
      // Find the matching item in combinedData.processedData
      return combinedData.processedData.find((processedItem) => processedItem.key === section.sectionKey);
    })
    // Filter out undefined entries in case there's no match
    .filter((item) => item !== undefined);

  // Update combinedData.processedData with the sorted array
  combinedData.processedData = sortedProcessedData;

  return combinedData;
};
