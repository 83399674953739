import { Box, IconButton, useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { PaginationDotIconProps } from '../../../types';

export const PaginationContainer = styled(Box)(() => {
  const { tokens } = useTheme();

  return {
    borderRadius: tokens.core.spacing['1_5'].value,
    backgroundColor: tokens.semantic.color.background.container.value,
    backgroud: tokens.semantic.color.background.base.value,
    padding: tokens.core.spacing['1_5'].value,
  };
});

export const CarouselItemContainer = styled(Box)(() => {
  return {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
  };
});

export const PaginationButtonContainer = styled(Box)(() => {
  const { tokens } = useTheme();
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: tokens.core.spacing['1_5'].value,
    columnGap: tokens.core.spacing['1'].value,
  };
});

export const PaginationDotsIconContainer = styled(Box)(() => {
  const { tokens } = useTheme();

  return {
    display: 'flex',
    justifyContent: 'center',
    columnGap: tokens.core.spacing['0_5'].value,
    alignItems: 'center',
  };
});

export const CarouselContainer = styled(Box)(() => {
  const { tokens } = useTheme();

  return {
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    scrollBehavior: 'smooth',
    columnGap: tokens.core.spacing['2'].value,
    alignItems: 'center',
    alignSelf: 'stretch',
  };
});

export const Gradient = styled(Box)(() => {
  return {
    position: 'absolute',
    right: 0,
    top: '0px',
    width: '47px',
    height: '65px',
    background: 'linear-gradient(270deg, #F3F3F3 29.5%, rgba(243, 243, 243, 0) 100%)',
    zIndex: 1,
  };
});

export const DotIcon = styled(IconButton)<PaginationDotIconProps>(({ isActive }) => {
  const { tokens, palette } = useTheme();

  return {
    height: 0,
    width: 0,
    flex: '0 0 auto',
    color: isActive ? tokens.semantic.color.status.new.value : palette.grey[500],
    backgroundColor: 'unset',

    ':focus:not(:focus-visible)': {
      outline: 'none',
      outlineStyle: 'unset',
      backgroundColor: 'unset',
      outlineOffset: 'unset',
    },
    ':not(.Mui-disabled):hover': {
      background: 'unset',
      color: tokens.semantic.color.status.new.value,
    },
    ':over': {
      outlineStyle: 'unset',
      color: tokens.semantic.color.status.new.value,
    },
  };
});
